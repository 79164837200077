import { ThemeContext, themes } from '../context/ThemeContext';
import { useContext, useState, useEffect, useRef } from "react";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, DateTime, DataLabel, ScatterSeries, Zoom  } from '@syncfusion/ej2-react-charts';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import TimelapsePast from '../assets/svg/TimelapsePast';
import TimelapseFuture from '../assets/svg/TimelapseFuture';
import SettingsIcon from '../assets/svg/SettingsIcon';
import HelpIcon from '../assets/svg/HelpIcon';
import { Col, Row } from 'react-bootstrap';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import MoldAttribute  from './MoldAttribute';
import { useTranslation } from 'react-i18next';
import MessageTranslate from './MessageTranslate';


// Datepicker fomrat:
// https://www.syncfusion.com/forums/143984/change-date-picker-view-format-to-week-or-month

   
const NoiseIDN = ({ selectedTool, widgetContent }) => {


    const { t } = useTranslation();
    const widgetTitle = t('widgets.' + widgetContent);
    let chart = useRef(null); 

    const axiosPrivate = useAxiosPrivate();   

    const currentTheme = useContext(ThemeContext);
    let BackgroundColor = currentTheme.theme.widgetBg;
    let TextColor = currentTheme.theme.textColor;

    let PrimaryColor = currentTheme.theme.primary;
    let Yellow = currentTheme.theme.yellowMid;
    let Red = currentTheme.theme.red;
    let Blue = currentTheme.theme.blue;
    let helpDialogInstance;


    createSpinner({
        target: document.getElementById('panel_11_content'),
    });

    const [message, setMessage] = useState(<MessageTranslate widgetKey={widgetContent} messageKey="messages.changeDateRangeOrMold" />);
    const date = new Date();
    const dateobj = new Date();
    const [statedNoiseData, setStatedNoiseData] = useState([]);
    
    const [dateRange, setDaterange] = useState(7);
   

    let firstDayofCurrentWeek = date.getDate() - date.getDay() + 1;
    firstDayofCurrentWeek =  new Date(date.setDate(firstDayofCurrentWeek)).toUTCString();
    
    let LSStart = localStorage.getItem('session_scatter_start');
    let LSEnd = localStorage.getItem('session_scatter_end');

    let lastDayofCurrentWeek = new Date(date.setDate(date.getDate()+6)).toUTCString();
    

    const [scatterDateRange, setScatterDateRange] = useState([new Date(LSStart ? LSStart : firstDayofCurrentWeek), new Date(LSEnd ? LSEnd : lastDayofCurrentWeek)]);// first and last day of current week

    let start = new Date(scatterDateRange[0]);
    let end = new Date(scatterDateRange[1]);

    const TZoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
   
    useEffect(() => {

        showSpinner(document.getElementById('panel_11_content'));
        let unMounted = false;
        const getNoiseData = async () => {
            if(!selectedTool == 0  ){ // selected = mold from map/grid

        
                let start = new Date (scatterDateRange[0] - TZoffset);
                start = start.toISOString().split('T', 1)[0];

                let ende = new Date (scatterDateRange[1] - TZoffset);
                ende = ende.toISOString().split('T', 1)[0];
                
                
                const response = await axiosPrivate.get('/molddata/noise/1/mold_id/'+ selectedTool +'/start/' + start + ' 00:00/end/' + ende + ' 23:00', {

            }).catch(function(error){
                if (error.response) {
                    console.error(error.response.data.message);
                }
            });
                !unMounted &&
                setStatedNoiseData([...response.data]);            
                if(response.data.length === 0){
                    setMessage(<MessageTranslate widgetKey={widgetContent} messageKey="messages.changeDateRange" />);
                }
            }
            else{
                setMessage(<MessageTranslate widgetKey={widgetContent} messageKey="messages.changeMold" />);   
            }
            hideSpinner(document.getElementById('panel_11_content'))   
           
        }

        getNoiseData();

        return () => {
            unMounted = true;
        }
    }, [selectedTool, scatterDateRange]);  

  
    const handleScatterDateChange = (start, ende) => { 
       let temparray = [start, ende] 
       setScatterDateRange(temparray);
       localStorage.setItem('session_scatter_start', start);
       localStorage.setItem('session_scatter_end', ende);
    };
    


    const marker = { visible: false, width: 2, height: 2, shape: 'Circle'};

    const [status, setStatus] = useState(false);

    const settingsButtonClick = () => {
        setStatus(!status);
    };
    const dialogClose = () => {
        setStatus(false);
  
    };
    const dialogOpen = () => {
        setStatus(true);
     
    };

    let buttons;
    const dlgButtonClick = () => {
        dialogClose();
    };
    buttons = [
        {
            click: dlgButtonClick,
            buttonModel: {
                content: t('buttons.save'),
                isPrimary: true,
            }
        }
    ];


    const onChange = (e) => {
        let start = e.startDate;
        let end = e.endDate;
        setDaterange(e.daySpan);
        handleScatterDateChange(start, end);
      }; 
      const toggleDatespan = (daterange, jumpto = 'future') => {

        daterange = (jumpto == "future" ? daterange : -daterange);
        start = new Date(start);
        end = new Date(end);

        let newstart =  new Date(start.setDate(start.getDate() + daterange));
        let newend = new Date(end.setDate(end.getDate() + daterange));  

        handleScatterDateChange(newstart, newend);
    };

   
    const data = statedNoiseData;

    const primaryxAxis = { 
        valueType: 'DateTime',  
        // title: 'Time', 
        labelFormat: 'dd.MM - h:mm',
        //  interval: 1, 
        labelRotation:  -45 , 
        labelStyle: {color: TextColor}, 
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        minorTickLines: { width: 0 },
        enableAutoIntervalOnZooming: true,
    };
    const primaryyAxis = {
        // title: 'Value',
        majorTickLines: { width: 0 },
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        minorTickLines: { width: 0 },
        lineStyle: {width: 0  },
        labelFormat: 'n1' ,
        labelStyle: {color: TextColor}, 
        // interval: 10,
    };
    const refreshChart = () => { 
        chart.current.refresh();
  
    }
    const margin = {  left: 100, right: 100, top: 8, bottom: 40};
    const helpButtonClick = () => {
        helpDialogInstance.show();
    }

    const loaded = () => {
        hideSpinner(document.getElementById('panel_11_content'));
    }
return (
<>   
    <div className="widgetheader">
        <div className="float-start">
            <h3>{(<MoldAttribute id={selectedTool} attributes={["mold_identifier", "code"]}/>)}</h3> 
        </div>
        <div className="float-end" onClick={settingsButtonClick} >
            <SettingsIcon color="#fff" />
        </div>
        <div className="float-end"  onClick={helpButtonClick}>
            <HelpIcon color="#fff" />
        </div>
        <DialogComponent 
            id="helper" 
            header={widgetTitle}
            showCloseIcon={true} 
            visible={false}
            width='480px' 
            height='240px'
            ref={dialog => helpDialogInstance = dialog} 
            closeOnEscape={false} 
            target='#panel_11_content'
            >  
            {t('descriptions.' + widgetContent)}
        </DialogComponent>

        <DialogComponent 
            id="defaultdialogScatter" 
            showCloseIcon={true} 
            zIndex = {9999}
            width="500px" 
            header={t('settings')}
            visible={status}
            target="#panel_11_content"
            buttons={buttons}
            open={dialogOpen}
            close={dialogClose}
        >
            <Row>                
                <Col>
                   {t('messages.noSettingsAvailable')}
                </Col>
            </Row>
        </DialogComponent>
        <div className="datecontainer">
            <DateRangePickerComponent 
                id="daterangepicker" 
                startDate={start} 
                endDate={end} 
                placeholder="Select a range" 
                change={onChange}
                format='dd.MM.yyyy'
                firstDayOfWeek = {1}
                max={dateobj}
            />
        </div>
    </div>
    <div className="timelapse past" onClick={() => toggleDatespan(dateRange, "past")}> <TimelapsePast color={TextColor} val={" - " + dateRange + "d"}  /> </div>
    {(new Date(scatterDateRange[1].getFullYear(), scatterDateRange[1].getMonth(), scatterDateRange[1].getDate()) >= new Date( new Date(dateobj.getFullYear(), dateobj.getMonth(), dateobj.getDate())) ? null : <div className="timelapse future" onClick={() => toggleDatespan(+1)}> <TimelapseFuture color={TextColor} val={" + " + dateRange + "d"} /> </div>)}



{(data.length == 0 ? <h2 className='text-center pt-5'> {message} </h2> : 
<div className='control-pane'>
    <ChartComponent 
        style={{ backgroundColor : BackgroundColor, color: TextColor}}
        background={BackgroundColor}
        primaryXAxis={primaryxAxis} 
        primaryYAxis={primaryyAxis} 
        margin={margin}         
        enableCanvas={true}
        enablePersistence={true}
        loaded = {loaded}

        zoomSettings={{
            enableSelectionZooming: true,
            enableScrollbar: true,
            toolbarItems: ['Reset'],
            mode: "X, Y",
        }}
        title={widgetTitle}
        titleStyle={{color: TextColor, fontWeight: '400', position: 'Top', fontSize : '10'}}
        chartArea={{ border: { width: 0 } }} 

        >
        <Inject services={[ScatterSeries, Legend, DataLabel, Category, Zoom, DateTime]}/>
        <SeriesCollectionDirective>
            <SeriesDirective dataSource={data} xName='time' yName='noise_idn_0' name="Sensor 1" type='Scatter' fill={PrimaryColor} opacity='0.7' marker={marker}></SeriesDirective>
            <SeriesDirective dataSource={data} xName='time' yName='noise_idn_1' name="Sensor 2" type='Scatter' fill={Red} opacity='0.7' marker={marker}></SeriesDirective>
            <SeriesDirective dataSource={data} xName='time' yName='noise_idn_2' name="Sensor 3" type='Scatter' fill={Blue} opacity='0.7' marker={marker}></SeriesDirective>
            <SeriesDirective dataSource={data} xName='time' yName='noise_idn_3' name="Sensor 4" type='Scatter' fill={Yellow} opacity='0.7' marker={marker}></SeriesDirective>
     
        </SeriesCollectionDirective>
       
    </ChartComponent>
    </div>
    
)}
</>
)

}
;
export default NoiseIDN;
