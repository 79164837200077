import React from "react";

import ProductionOutput24H from "../components/ProductionOutput24H";
import ProductionRatio from "../components/ProductionRatio";
import ProductionOutputDaily from "../components/ProductionOutputDaily";
import NoiseIDNAverage from "../components/NoiseIDNAverage";
import NoiseIDN from "../components/NoiseIDN";
import Centering from "../components/Centering";
import TemperatureChart from "../components/TemperatureChart";
import Breathing from "../components/Breathing";
import BreathingBoxPlot from "../components/BreathingBoxPlot";
import FallbackPanel from "../components/FallbackPanel";



const Components = {
  production_output_24h: ProductionOutput24H,
  production_ratio: ProductionRatio,
  production_output_daily: ProductionOutputDaily,
  noise_idn_average: NoiseIDNAverage,
  noise_idn: NoiseIDN,
  centering: Centering,
  temperature: TemperatureChart,
  breathing: Breathing,
  breathing_box: BreathingBoxPlot,
  fallbackPanel: FallbackPanel,
};
// block - Widget content == funktionsaufruf + key für Übersetzung
export default (block, selectedTool) => {
  if (typeof Components[block] !== "undefined") {
    return React.createElement(Components[block], {
      selectedTool: selectedTool, 
      widgetContent: block
    });
  }
  return React.createElement(
    () => <div>The component {block} has not been created yet.</div>,
    { key: block }
  );
};
