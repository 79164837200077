import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ColorPickerComponent, NumericTextBoxComponent   } from '@syncfusion/ej2-react-inputs';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { t } from 'i18next';

// Definiere die Stateless Functional Component
const LimitDynamic = ({ name, color1, setColor1, color2, setColor2, average, setAverage, tolerance1, setTolerance1, tolerance2, setTolerance2, lowerToleranceActive, setLowerToleranceActive, placeholder, max, min }) => {
    
    const handleAverageChange = (to) => {
        setAverage(to.value);
    };
    const handleTolerance1Change = (to) => {
        setTolerance1(to.value);
    };
    const handleTolerance2Change = (to) => {
        setTolerance2(to.value);
    };
    const handleColor1Change = (to) => {
        setColor1(to.currentValue.hex);
    };
    const handleColor2Change = (to) => {
        setColor2(to.currentValue.hex);
    };
    const handleActiveChange = (to) => {
        setLowerToleranceActive(to.checked);
    };


  return (
    <>

        <Col sm={5} className='mt-3'>
            <div className="pt-3">
               {t('average.averagePastShots')}
            </div>
        </Col>
        <Col sm={4}>
            <NumericTextBoxComponent id={`dynamicAverage_${name}`} format='###' value={average} placeholder='' floatLabelType='Auto' change={(e) => handleAverageChange(e)}/>
        </Col>
        <b className='mt-2'> {t('limits.upperBand')}</b>
        <Col sm={3}>
            <ColorPickerComponent id={`colorUpper_${name}`} value={color1} mode="Palette" cssClass='mt-2' change={(to) => handleColor1Change(to)} name="cp2" cssClass="mt-4"/>
        </Col>
        <Col sm={6}>
            <NumericTextBoxComponent
                id={`dynamicUpperTolerance_${name}`}
                format='p2' 
                value={tolerance1} 
                min={0}
                max={1} 
                step={0.01} 
                placeholder={t('limits.tolerance')}
                change={(e) => handleTolerance1Change(e)} 
                floatLabelType='Auto'
            />
           
        </Col>
        <b className='mt-2'> {t('limits.lowerBand')}</b>
        <Col sm={3}>
            <ColorPickerComponent id={`colorLower_${name}`} cssClass='mt-2' value={color2} mode="Palette" change={(to) => handleColor2Change(to)} name="cp2" cssClass="mt-4"/>
        </Col>
        <Col sm={6}>
            <NumericTextBoxComponent
                id={`dynamicLowerTolerance_${name}`}
                format='p2' 
                value={(lowerToleranceActive ? tolerance2: tolerance1)} 
                enabled={(lowerToleranceActive)} 
                min={0}
                max={1} 
                step={0.01} 
                placeholder={t('limits.tolerance')}
                change={(e) => handleTolerance2Change(e)} 
                floatLabelType='Auto'
            />
           
        </Col>
        <Col sm={3}>
            <CheckBoxComponent id={`dynamicLowerToleranceActive_${name}`} checked={lowerToleranceActive} change={(to) => handleActiveChange(to)} cssClass='mt-3'/>
        </Col>
       
    </>
  );
};

export default LimitDynamic;