import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Category, StripLine, ChartAnnotation, AnnotationsDirective, AnnotationDirective, ColumnSeries, DataLabel, Legend, Tooltip,RangeColorSettingsDirective, RangeColorSettingDirective } from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';
import { ThemeContext, themes } from '../context/ThemeContext';
import { useContext, useState, useRef, useEffect } from "react";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import TimelapsePast from '../assets/svg/TimelapsePast';
import TimelapseFuture from '../assets/svg/TimelapseFuture';
import SettingsIcon from '../assets/svg/SettingsIcon';
import { Col, Row } from 'react-bootstrap';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import HelpIcon from '../assets/svg/HelpIcon';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { SliderComponent } from '@syncfusion/ej2-react-inputs';
import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import LimitStatic  from './LimitStatic';
import MoldAttribute  from './MoldAttribute';
import { useTranslation } from 'react-i18next';
import MessageTranslate from './MessageTranslate';


const ProductionOutput24H = ({ selectedTool, widgetContent }) => {

    let chart = useRef(); 
    const axiosPrivate = useAxiosPrivate();      

    const { t } = useTranslation();

    const widgetTitle = t('widgets.' + widgetContent);

    createSpinner({
        target: document.getElementById('panel_4_content'),
    });

    const [message, setMessage] = useState(<MessageTranslate widgetKey={widgetContent} messageKey="messages.changeDateOrMold" />);

    let ticks = { placement: "After",   
        largeStep: 4,
        smallStep: 2,
        showSmallTicks: true};
  
    const currentTheme = useContext(ThemeContext);
    let BackgroundColor = currentTheme.theme.widgetBg;
    let TextColor = currentTheme.theme.textColor;
    let PrimaryColor = currentTheme.theme.primary;
    let Yellow = currentTheme.theme.yellowMid;
    let Red = currentTheme.theme.red;

    let AverageObject = (localStorage.getItem('ProductionOutput24Average') ? JSON.parse(localStorage.getItem("ProductionOutput24Average")) : null);

    const [averageFrom, setAverageFrom] = useState(AverageObject ? AverageObject.averageFrom : 1);
    const [startTime, setStartTime] = useState(AverageObject ? AverageObject.AverageStartTime  : 0);  
    const [endTime, setEndTime] = useState(AverageObject ? AverageObject.AverageEndTime  : 0);  

    let rangeVal = [(startTime != 0 ? Number(startTime) : 9), (endTime != 0 ? Number(endTime) : 17)];   // timerange for average hours

    let LSToday = localStorage.getItem('session_barchart_today');

    const dateobj = new Date();
    const today = new Date((LSToday ? LSToday : dateobj)); //today
    const [barChartDate, setBarChartDate] = useState(today); 
    const [statedProduktion, setStatedProduktion] = useState([]);

    let LimitStaticObject = (localStorage.getItem("ProductionOutput24LimitStatic") ? JSON.parse(localStorage.getItem("ProductionOutput24LimitStatic")) : null);
  //  const [notifyStatic , setNotifyStatic ]= useState(LimitStaticObject ? LimitStaticObject.notify : false);
    const [color1, setColor1]= useState(LimitStaticObject ? LimitStaticObject.color1 : Red);
    const [color2, setColor2]= useState(LimitStaticObject ? LimitStaticObject.color2  : Yellow);
    const [limitStatic1, setLimitStatic1]= useState(LimitStaticObject ? LimitStaticObject.limitStatic1 : 700);
    const [limitStatic2, setLimitStatic2] = useState(LimitStaticObject ? LimitStaticObject.limitStatic2 : 400);
    
    let date = new Date(barChartDate);

    useEffect(() => {
        let unMounted = false;
        showSpinner(document.getElementById('panel_4_content'));
        const getBarChartData = async () => {
            if(!selectedTool == 0 ){
                let date = barChartDate.toISOString().split('T', 1)[0];
                const response = await axiosPrivate.get('/molddata/cycles/production/mold_id/'+ selectedTool +'/interval/hourly/start/' + date + ' 00:00/end/' + date + ' 23:00', {

                }).catch(function(error){
                    if (error.response) {
                        console.error(error.response.data.message);
                    }
                });
                !unMounted &&
                setStatedProduktion([...response.data]);             
            }
            else{
                setMessage(<MessageTranslate widgetKey={widgetContent} messageKey="messages.changeMold" />); 
            }
            hideSpinner(document.getElementById('panel_4_content'))
        }

        getBarChartData();
        return () => {
            unMounted = true;
        }
    }, [selectedTool, barChartDate])

    
    const handleBarChartDateChange = (date) => { 
        setBarChartDate(date);
        localStorage.setItem('session_barchart_today', date);
    };

    const color3 = [PrimaryColor];

    
    let tooltip = { placement: "Before", isVisible: true};

    let prodData = statedProduktion;
    const [status, setStatus] = useState(false);

    let helpDialogInstance;

    const settingsButtonClick = () => {
        setStatus(!status);
    };
    const dialogClose = () => {
        setStatus(false);
    };
    const dialogOpen = () => {
        setStatus(true);
    };

    let buttons;
    const dlgButtonClick = () => {

        localStorage.setItem("ProductionOutput24LimitStatic", null); 
            //anpassen
            let th1 = Number(document.getElementById('value_ProductionOutput24hstatic1').value);
            let th2 = Number(document.getElementById('value_ProductionOutput24hstatic2').value);
            setLimitStatic1(th1)
            setLimitStatic2(th2)
            const staticObject = {
                limitStatic1 : th1, 
                limitStatic2 : th2, 
                color1 : color1, 
                color2 : color2, 
               // notify : notifyStatic,
            }
            localStorage.setItem('ProductionOutput24LimitStatic', JSON.stringify(staticObject)); 
    
            const averageObject = {
                averageFrom : averageFrom,
                AverageStartTime : (averageFrom == 3 ? startTime : 9 ), 
                AverageEndTime : (averageFrom == 3 ? endTime : 17 ), 
                
            }
            localStorage.setItem('ProductionOutput24Average',  JSON.stringify(averageObject)); 
        
        dialogClose(); 
        refreshChart();
       
    };
    buttons = [
        {
            click: dlgButtonClick,
            buttonModel: {
                content:t('buttons.save'),
                isPrimary: true,
            }
        }
    ];
    useEffect(() => {
        refreshChart();
       }, [startTime, endTime, averageFrom]);

    let dataForAverage;
    let averageAdd;
    let average;


        if(averageFrom == 1){ // remove null entries
            const arrN = prodData.filter((item) => item.y !== null);
            dataForAverage = arrN;
        }
        else if(averageFrom == 3){ // user timerange
    
            let compStarttime = (startTime < 10 ? "0" : "") + startTime + ":00";
            let compEndtime = (endTime < 10 ? "0" : "") + endTime + ":00";
            const arrS = prodData.filter((item) => item.x >= compStarttime);
            const arrE = arrS.filter((item) => item.x <= compEndtime);
            dataForAverage = arrE;
            averageAdd = compStarttime + ' - ' + compEndtime;
        }
        else{
            dataForAverage = prodData;
        }
        
        
        let total  =  (dataForAverage.reduce((total,currentValue) =>  total  = total  + currentValue.y , 0 ) / dataForAverage.length);
        average = total.toFixed(0);
    
    
    const onDateChange = (e) => {
        let date = e.value;
        handleBarChartDateChange(date);
    }; 

    const toggleDay = (val) => {
        let tempDate = new Date(date);
        tempDate.setDate(tempDate.getDate() + val);
        handleBarChartDateChange(tempDate);
    };

    const onRenderingTicks = (to) => { // TimeFormat from number (to.value)
        to.text = (to.value < 10 ? "0" : "") + to.value + ":00";
    }

    const onTooltipChange = (to) => {     //  TimeFormat from number (to.value[0])
        let val1 = (to.value[0] < 10 ? "0" : "") + to.value[0] + ":00";
        let val2 = (to.value[1] < 10 ? "0" : "") + to.value[1] + ":00";
        to.text = val1 + ' - ' + val2;
    }


    const refreshChart = () => {   
      if(chart.current !== undefined) {
        chart.current.refresh();
      } 
    }
    const helpButtonClick = () => {
        helpDialogInstance.show();
    }
    const handleRadioChange = (f) => {
        setAverageFrom(f);
    
    }
    const handleTimeChange = (tc) => {
        setStartTime(tc.value[0]);
        setEndTime(tc.value[1]);

    }
    const maxValue = Math.max(...prodData.map(o => o.y))

    const pointRender = (args) => {
        if(args.point.yValue < 16){
            args.series.properties.cornerRadius.properties.topLeft = args.point.yValue;
            args.series.properties.cornerRadius.properties.topRight = args.point.yValue;
            args.series.properties.cornerRadius.properties.bottomRight = 0;
        }
        else if(args.point.yValue/maxValue < 0.05) {
            args.series.properties.cornerRadius.properties.topLeft = 2;
            args.series.properties.cornerRadius.properties.topRight = 2;
            args.series.properties.cornerRadius.properties.bottomRight = 0;

        }
        else{
            args.series.properties.cornerRadius.properties.topLeft = 16;
            args.series.properties.cornerRadius.properties.topRight = 16;
            args.series.properties.cornerRadius.properties.bottomRight = 16;   
        }
    }
    const loaded = () => {
        hideSpinner(document.getElementById('panel_4_content'));
       }
    const margin = { left: 16, right: 16, top: 8, bottom: 30 };
return (
<>   
    <div className="widgetheader">
    <div className="float-start">
        <h3>{(<MoldAttribute id={selectedTool} attributes={["mold_identifier", "code"]}/>)}</h3> 
    </div>
        <div className="float-end" onClick={settingsButtonClick} >
            <SettingsIcon color="#fff" />
       </div>        
       <div className="float-end"  onClick={helpButtonClick}>
            <HelpIcon color="#fff" />
        </div>
        <DialogComponent 
            id="helper" 
            header={widgetTitle}
            showCloseIcon={true} 
            visible={false}
            width='350px' height='250px' 
            ref={dialog => helpDialogInstance = dialog} 
            closeOnEscape={false} 
            target='#panel_4_content'
            > 
               {t('descriptions.' + widgetContent)}
        </DialogComponent>
        <DialogComponent 
            id="defaultdialog" 
            target="#panel_4_content"
            showCloseIcon={true} 
            zIndex = {9999}
            width="500px" 
            header={t('settings')} 
            visible={status}
            buttons={buttons}
            open={dialogOpen}
            close={dialogClose}
            >
            <Row>
                <Col sm={3} className="textcenter">
                   {t('average.averageFrom')}
                </Col>  
                <Col sm={9} className='mb-3'>
                <ul>
                    <li><RadioButtonComponent label={t('availableData')} name="averageFrom" checked={averageFrom == 1 ? 'checked' : ''} change={() => handleRadioChange(1)}/></li>
                    <li><RadioButtonComponent label={t('completeDay')} name="averageFrom" checked={averageFrom == 2 ? 'checked' : ''} change={() => handleRadioChange(2)}/></li>
                    <li><RadioButtonComponent label={t('customTimePeriod')} name="averageFrom" checked={averageFrom == 3 ? 'checked' : ''} change={() => handleRadioChange(3)}/></li>
                </ul>
                </Col>
                {averageFrom == 3 ? 
                <Col sm={{span: 10, offset: 1}} className='mb-5'>
             
                    <>                     
                        <SliderComponent 
                            id='range' 
                            type='Range' min={0} max={23} 
                            tooltip={tooltip} 
                            ticks={ticks} 
                            value={[rangeVal[0], rangeVal[1]]}
                            renderingTicks={(to) => onRenderingTicks(to)}
                            tooltipChange = { (to => onTooltipChange(to))}
                            changed={(tc) => handleTimeChange(tc)}
                        />
                    </>     

                     
                </Col>
                :  ''}

                <LimitStatic name="ProductionOutput24hstatic2" color={color2} setColor={setColor2} value={limitStatic2} setValue={setLimitStatic2} placeholder={t('colorMedium')} useActivate={false} min={limitStatic1}  />
                <LimitStatic name="ProductionOutput24hstatic1" color={color1} setColor={setColor1} value={limitStatic1} setValue={setLimitStatic1} placeholder={t('colorLow')} useActivate={false} max={limitStatic2}  />
            </Row>
        </DialogComponent>
      
        <div className="datecontainer">
            <DatePickerComponent
                value={date} 
                placeholder="Select Date" 
                change={onDateChange}
                // locale='de-de'
                format='dd.MM.yyyy'
                firstDayOfWeek = {1}
                max = {dateobj} 
            />
        </div>
    </div>

    <div className="timelapse past" onClick={() => toggleDay(-1)}> <TimelapsePast color={TextColor} val="-1d"  /> </div>
    {(new Date(barChartDate.getFullYear(), barChartDate.getMonth(), barChartDate.getDate()) >= new Date( new Date(dateobj.getFullYear(), dateobj.getMonth(), dateobj.getDate())) ? null : <div className="timelapse future" onClick={() => toggleDay(+1)}> <TimelapseFuture color={TextColor} val="+1d" /> </div>)}


{(prodData.every(obj => obj.y === null) ? <h2 className='text-center pt-5'>{message}</h2> : 
<div className='control-pane'>

    <div className='control-section'>

        <ChartComponent 
            id='charts2'
            ref={chart} 
            style={{ textAlign: "center", backgroundColor : BackgroundColor, color: TextColor}} 
            enableSideBySidePlacement={false} 
            margin = {margin}
            height = '100%'
            title= {widgetTitle}
            titleStyle={{color: TextColor, fontWeight: '400', position: 'Top'}}
            primaryXAxis={{
                valueType: 'Category', 
                interval: 1, 
                majorGridLines: { width: 0 },
                majorTickLines: { width: 0 },
                minorTickLines: { width: 0 }, 
                labelStyle: {color: TextColor},
                labelPosition: 'Outside', 
                labelRotation: Browser.isDevice ? -45 : 0, 
                labelIntersectAction: Browser.isDevice ? 'None' : 'Rotate45',
            }} 
            primaryYAxis={{ 
                title: 'Zyklen', 
                labelFormat: '{value}', 
                majorTickLines: { width: 0 }, 
                majorGridLines: { width: 0 },
                minorTickLines: { width: 0 }, 
                lineStyle: { width: 0 },
                visible: false,
                stripLines: [{ start: average, size: (average == 0 ? 0 : 1), sizeType: 'Pixel', dashArray: "8,4", color: Red }]
            }} 
            chartArea={{ border: { width: 0 } }} 
            width={Browser.isDevice ? '100%' : '77%'} 
            tooltip={{
                enable: true, 
                header: "<b>${point.x}</b>", 
                format: t('rate') + ' : <b>${point.y}</b>'
            }} 
            loaded = {loaded}
            pointRender={pointRender}
            legendSettings={{
                mode: 'Range',
                visible: true,
                toggleVisibility: false,
                textStyle: { color: TextColor },
            }}>
            <Inject services={[ColumnSeries, DataLabel, Category, Tooltip, Legend, StripLine, ChartAnnotation]}/>
       
                <SeriesCollectionDirective>
                    <SeriesDirective 
                        xName='x' 
                        yName='y' 
                        columnWidth={0.8} 
                        dataSource={prodData} 
                        type='Column'  
                        marker={{ 
                            dataLabel: { 
                                visible: true, 
                                enableRotation:  Browser.isDevice ? true : false, 
                                angle: -90, 
                                position: 'Outer', 
                                font: { fontWeight: '600', color: TextColor } 
                            } 
                        }} 
                        cornerRadius={{ 
                            topLeft: Browser.isDevice ? 8 : 16, 
                            topRight: Browser.isDevice ? 8 : 16, 
                            bottomRight:  Browser.isDevice ? 8 : 16,  
                        }}
                    >
                </SeriesDirective>
            </SeriesCollectionDirective>
            <RangeColorSettingsDirective>
                <RangeColorSettingDirective label="-" start={1} end={limitStatic1} colors={[color1]}></RangeColorSettingDirective>
                <RangeColorSettingDirective label="-" start={limitStatic1} end={limitStatic2} colors={[color2]}></RangeColorSettingDirective>
                <RangeColorSettingDirective label="-" start={(limitStatic2 == 0 ? 1 : limitStatic2)} end={1000000} colors={[color3]}></RangeColorSettingDirective>
            </RangeColorSettingsDirective>
            <AnnotationsDirective>
                <AnnotationDirective 
                    content={average == 0 ? '' : '<div style="color: '+ Red + '; margin-left: -148px">' + t('average.average') + ': ' + average  + (averageAdd ? ' <br /> ' + averageAdd : '') + '</div>'} 
                    region='Chart' 
                    coordinateUnits='Point' 
                    x={'00:00'} 
                    y={average} 
                />
          
            </AnnotationsDirective>
        </ChartComponent>
 
    </div>
</div>
)}
</>);

}
 
export default ProductionOutput24H;