import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './locales/de.json';
import en from './locales/en.json';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: [ 'cookie', 'localStorage', 'navigator', 'htmlTag'],
        //  caches: ['localStorage'] // Speichert die Sprache in `localStorage`
      caches: ['cookie'],
      cookieMinutes: 10080, // Cookie-Lebensdauer  eine Woche
    },
    // debug: true,
    resources: {
      de: {
        translation: de
      },
      en: {
        translation: en
      }
    },
    lng: 'en', // Standardsprache
    fallbackLng: 'en', // Fallback-Sprache, wenn die gewünschte Übersetzung fehlt
    interpolation: {
      escapeValue: false // Escaping < > &
    }
  });

export default i18n;