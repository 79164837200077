import {useState  } from "react";
import { useTranslation } from 'react-i18next';
import { languages } from '../config/languages';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const currentLanguage= i18n.language;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const fields = { text: 'text', iconCss: 'Class', value: 'value' };
  return (
    <>
      <DropDownListComponent  dataSource={languages} fields={fields} value={currentLanguage} change={(e) => changeLanguage(e.itemData.value)}/>
    </>
  );
};

export default LanguageSwitcher;
