import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { useState, useEffect, useRef } from "react";
import { overviewElements } from '../config/overviewElements';
import * as React from 'react';
import { Card, Row, Col, Alert } from 'react-bootstrap';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';
import OverviewSetSmall from '../components/OverviewSetSmall';
import OverviewSetBig from '../components/OverviewSetBig';

const OverviewSettings = () => {


   
    const { t } = useTranslation();
  
    let item = t('widgets.widget_plural');
   
    return (<>

<Card.Title> {t('overviewSettings')} </Card.Title> 
<Card.Body className="overviewSettings">

    <Row>
        <Col xs={12} className="mb-5"> 
       
             <OverviewSetSmall />
           
        </Col>

        <Col xs={12} className="mb-5"> 
       
       
             <OverviewSetBig />
           
        </Col>
       
    </Row>

    </Card.Body>
  </>);
}
export default OverviewSettings;
