import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate";


const MoldAttribute = ({ id, attributes }) => {

    const axiosPrivate = useAxiosPrivate();
    const [mold, setMold] = useState([]);
    const [attributeValue, setAttributeValue] = useState(null);

    useEffect(() => {

       

     
        const getMoldData = async () => {
            if(id){
            try {
                // const response = await axiosPrivate.get('/companies/' + auth.userInfo.companies_id, {});
                const response = await axiosPrivate.get('/molds/' + id, {});
            
                setMold(response.data);
               
            } catch (error) {
                console.log(error);
            }
        }
        }

        getMoldData();

        return () => {
        }
    }, [])
    const isEmpty = (value) => {
        return (
            value === null || 
            value === undefined || 
            value === "" || 
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === "object" && !Array.isArray(value) && Object.keys(value).length === 0)
        );
    }

    const findAttribute = (data, keys) => {
    
        for (const key of keys) {
            if (key in data && !isEmpty(data[key])) {

                setAttributeValue(data[key]);
                return; 
                        
            }
        }
    };
    useEffect(() => {
        findAttribute(mold, attributes );
       
    }, [mold]);
  
    return (
        <>
            {(attributeValue ? attributeValue : "Attribute not found")}
        </>
    );
};

export default MoldAttribute;
