import { ThemeContext, themes } from '../context/ThemeContext';
import { useContext, useState, useEffect, useRef } from "react";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, DateTime, DataLabel, BoxAndWhiskerSeries, LineSeries, Zoom, StripLine, Tooltip  } from '@syncfusion/ej2-react-charts';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { SwitchComponent,CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import TimelapsePast from '../assets/svg/TimelapsePast';
import TimelapseFuture from '../assets/svg/TimelapseFuture';
import SettingsIcon from '../assets/svg/SettingsIcon';
import HelpIcon from '../assets/svg/HelpIcon';
import { Col, Row } from 'react-bootstrap';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import LimitStatic  from './LimitStatic';
import MoldAttribute  from './MoldAttribute';
import { useTranslation } from 'react-i18next';
import MessageTranslate from './MessageTranslate';


// Datepicker format:
// https://www.syncfusion.com/forums/143984/change-date-picker-view-format-to-week-or-month

const BreathingBoxPlot = ({ selectedTool, widgetContent }) => {

    let chart = useRef(null); 
    const { t } = useTranslation();
    const widgetTitle = t('widgets.' + widgetContent);
    const axiosPrivate = useAxiosPrivate(); 
    const [breathingData, setBreathingData] = useState([]);
    createSpinner({
        target: document.getElementById('panel_12_content'),
    });

    const [message, setMessage] = useState(<MessageTranslate widgetKey={widgetContent} messageKey="messages.changeDateRangeOrMold" />);

    const date = new Date();
    const dateobj = new Date();
    
    const [dateRange, setDaterange] = useState(7);

    const currentTheme = useContext(ThemeContext);
    let BackgroundColor = currentTheme.theme.widgetBg;
    let TextColor = currentTheme.theme.textColor;

    let PrimaryColor = currentTheme.theme.primary;
    let PrimaryColorTrans = "rgba(8, 167, 127, .3)";
    let Yellow = currentTheme.theme.yellowMid;
    let Red = currentTheme.theme.red;
    let Blue = currentTheme.theme.blue;
  
    let LimitStaticObject = (localStorage.getItem("BreathingBoxPlotLimitStatic") ? JSON.parse(localStorage.getItem("BreathingBoxPlotLimitStatic")) : null);
    const [staticLimit, setStaticLimit] = useState(LimitStaticObject ? LimitStaticObject.limitStaticActive : false);
    const [notifyStatic , setNotifyStatic]= useState(LimitStaticObject ? LimitStaticObject.notify : false);
    const [color1, setColor1]= useState(LimitStaticObject ? LimitStaticObject.color1 : Red);
    const [color2, setColor2]= useState(LimitStaticObject ? LimitStaticObject.color2  : Yellow);
    const [limitStatic1, setLimitStatic1]= useState(LimitStaticObject ? LimitStaticObject.limitStatic1 : 100);
    const [limitStatic2, setLimitStatic2] = useState(LimitStaticObject ? LimitStaticObject.limitStatic2 : 90);

    let LimitStaticObject2 = (localStorage.getItem("BreathingBoxPlotLimitStatic2") ? JSON.parse(localStorage.getItem("BreathingBoxPlotLimitStatic2")) : null);
    const [staticLimit2, setStaticLimit2] = useState(LimitStaticObject2 ? LimitStaticObject2.limitStaticActive : false);
    const [notifyStatic2 , setNotifyStatic2 ]= useState(LimitStaticObject2 ? LimitStaticObject2.notify : false);
    const [color3, setColor3]= useState(LimitStaticObject2 ? LimitStaticObject2.color1 : Blue);
    const [color4, setColor4]= useState(LimitStaticObject2 ? LimitStaticObject2.color2  : PrimaryColor);
    const [limitStatic3, setLimitStatic3]= useState(LimitStaticObject2 ? LimitStaticObject2.limitStatic1 : (staticLimit ? limitStatic1 : 110));
    const [limitStatic4, setLimitStatic4] = useState(LimitStaticObject2 ? LimitStaticObject2.limitStatic2 : (staticLimit ? limitStatic2 : 80));

    
    let firstDayofCurrentWeek = date.getDate() - date.getDay() + 1;
    firstDayofCurrentWeek =  new Date(date.setDate(firstDayofCurrentWeek)).toUTCString();
    let lastDayofCurrentWeek = new Date(date.setDate(date.getDate()+6)).toUTCString();
    

    let LSStart = localStorage.getItem('session_breathingBoxPlot_start');
    let LSEnd = localStorage.getItem('session_breathingBoxPlot_end');


    const [breathingDateRange, setBreathingDateRange] = useState([new Date(LSStart ? LSStart : firstDayofCurrentWeek), new Date(LSEnd ? LSEnd : lastDayofCurrentWeek)]);// first and last day of current week

    let start = new Date(breathingDateRange[0]);
    let end = new Date(breathingDateRange[1]);

    const TZoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds

   
    let helpDialogInstance;

    useEffect(() => {
        showSpinner(document.getElementById('panel_12_content'));
        let unMounted = false;
        const getCenterData = async () => {
            if(!selectedTool == 0  ){ // selected = mold from map/grid

                let start = new Date (breathingDateRange[0] - TZoffset);
                start = start.toISOString().split('T', 1)[0];

                let ende = new Date (breathingDateRange[1] - TZoffset);
                ende = ende.toISOString().split('T', 1)[0];
       
                
                const response = await axiosPrivate.get('/molddata/breathing/1/mold_id/'+ selectedTool +'/start/' + start +' 00:00/end/' + ende +' 23:00', {

            }).catch(function(error){
                if (error.response) {
                    console.error(error.response.data.message);
                }
            });
            !unMounted &&
            setBreathingData([...response.data])
            if (response.data.length === 0) {
                setMessage(<MessageTranslate widgetKey={widgetContent} messageKey="messages.changeDateRange" />);
            }

            }
            else{
                setMessage(<MessageTranslate widgetKey={widgetContent} messageKey="messages.changeMold" />);
            }
            hideSpinner(document.getElementById('panel_12_content'))
        
        }

        getCenterData();

        return () => {
            unMounted = true;
        }
    }, [selectedTool, breathingDateRange]);  


    const [status, setStatus] = useState(false);

    const buttonClick = () => {
        setStatus(!status);
    };
    const dialogClose = () => {
        setStatus(false);
  
    };
    const dialogOpen = () => {
        setStatus(true);
     
    };

    let buttons;
    const dlgButtonClick = () => {
        localStorage.setItem("BreathingBoxPlotLimitStatic", null); 

        if(staticLimit){
            const staticObject = {
                limitStaticActive : staticLimit,
                limitStatic1 : limitStatic1, 
                limitStatic2 : limitStatic2, 
                color1 : color1, 
                color2 : color2, 
                notify : notifyStatic,
            }
            localStorage.setItem('BreathingBoxPlotLimitStatic', JSON.stringify(staticObject)); 
        }
        localStorage.setItem("BreathingBoxPlotLimitStatic2", null); 

        if(staticLimit2){
            const staticObject2 = {
                limitStaticActive : staticLimit2,
                limitStatic1 : limitStatic3, 
                limitStatic2 : limitStatic4, 
                color1 : color3, 
                color2 : color4, 
                notify : notifyStatic2,
            }
            localStorage.setItem('BreathingBoxPlotLimitStatic2', JSON.stringify(staticObject2)); 
        }
        dialogClose();
    };
    buttons = [
        {
            click: dlgButtonClick,
            buttonModel: {
                content: t('buttons.save'),
                isPrimary: true,
            }
        }
    ];



    const onChange = (e) => {
        let start = e.startDate;
        let end = e.endDate;
        setDaterange(e.daySpan);
        handleBreathingDateChange(start, end);
      }; 
      const toggleDatespan = (daterange, jumpto = 'future') => {

        daterange = (jumpto == "future" ? daterange : -daterange);
        start = new Date(start);
        end = new Date(end); 

        let newstart =  new Date(start.setDate(start.getDate() + daterange));
        let newend = new Date(end.setDate(end.getDate() + daterange));  

        handleBreathingDateChange(newstart, newend);
    };
    const handleBreathingDateChange = (start, ende) => { 
        let temparray = [start, ende] 
        setBreathingDateRange(temparray);
        localStorage.setItem('session_breathingBoxPlot_start', start);
        localStorage.setItem('session_breathingBoxPlot_end', ende);
     };
    let rgba = ' rgba(256,256,256,.3) ';

    const marker =  {
        visible: true, 
        width: 3, 
        height: 3, 
        fill: PrimaryColor, 
        border: { width: 0,   },
        dataLabel: { visible: false, position: 'Top',   font: { fontWeight: '600', color: TextColor }  },
        // width: 4, height: 4, shape: 'Circle'
    };

    const primaryxAxis = { 
        valueType: 'Category',  
        rangePadding: 'Additional',
        majorGridLines: { width: 0 },labelStyle: {color: TextColor} 
    };
    const primaryyAxis = {  
        majorGridLines: { width:1, color: 'rgba(50, 50, 50 ,.5)' }, 
        interval: 20, 
        rangePadding: 'Additional',
        majorTickLines: { width: 0 } ,
        labelStyle: {color: TextColor}, 
        stripLines: [
        { start: limitStatic2, end: limitStatic1,  color: PrimaryColor, visible: (staticLimit ? true : false ), opacity: 0.3 }, 
        { start: limitStatic4, end: limitStatic3,  color: PrimaryColor, visible: (staticLimit2 ? (staticLimit ? false : true) : false ), opacity: 0.3 }, 
        { start: limitStatic1, end: (staticLimit2 ? limitStatic3 : 999999999999999),  color: Yellow, visible: (staticLimit ? true : false ), opacity: 0.3 }, 
        { start: (staticLimit2 ? limitStatic4 : -999999999999999), end: limitStatic2,  color: Yellow, visible: (staticLimit ? true : false ), opacity: 0.3 },
        { start: limitStatic3, end: 999999999999999, color: Red, visible: (staticLimit2 ? true : false ), opacity: 0.3 },
        { start: -999999999999999, end: limitStatic4,  color: Red, visible: (staticLimit2 ? true : false ), opacity: 0.3 }
    ],};

    const refreshChart = () => { 
        chart.current.refresh();
  
    }
    const margin = {  left: 100, right: 100, top: 8, bottom: 40};
const helpButtonClick = () => {
    helpDialogInstance.show();
}
const loaded = () => {
    hideSpinner(document.getElementById('panel_12_content'));
   }

   function groupByDay(data) {
    // Zuerst gruppieren wir die Daten nach Tagen wie zuvor
    const grouped = data.reduce((acc, curr) => {
    //   const date = new Date(curr.time).toISOString().split('T')[0];

    const orgDate = new Date (curr.time);
    const day = String(orgDate.getDate()).padStart(2, '0');    // Hole den Tag und füge führende Null hinzu
    const month = String(orgDate.getMonth() + 1).padStart(2, '0'); // Monate beginnen bei 0, daher +1
    const year = orgDate.getFullYear();

    const date = day + '.' + month + '.' + year;
      
      if (!acc[date]) {
        acc[date] = [];
      }
  
      acc[date].push(curr.breathing);
  
      return acc;
    }, {});
  
    // Dann das gruppierte Objekt in das  Array von Objekten
    return Object.keys(grouped).map(date => ({
      time: date,
      breathing: grouped[date],
    }));
  }

  const [groupedData, setGroupedData] = useState([]);
  let test = 0;

  useEffect(() => {
    if(breathingData.length > 0){
        setGroupedData(groupByDay(breathingData));
        
    }
  
}, [breathingData]);



return (
<>   
    <div className="widgetheader">
        <div className="float-start">
            <h3>{(<MoldAttribute id={selectedTool} attributes={["mold_identifier", "code"]}/>)}</h3> 
        </div>
        <div className="float-end" onClick={buttonClick} >
            <SettingsIcon color="#fff" />
        </div>
        <div className="float-end"  onClick={helpButtonClick}>
            <HelpIcon color="#fff" />
        </div>
        <DialogComponent 
            id="helper" 
            header={widgetTitle}
            showCloseIcon={true} 
            visible={false}
            width='640px' height='800px' 
            ref={dialog => helpDialogInstance = dialog} 
            closeOnEscape={false} 
            target='#panel_12_content'
            > 
            <table border="1">
                <thead>
                    <tr>
                    <th>Abbreviation</th>
                    <th>Description (German)</th>
                    <th>Description (English)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>Q1</td>
                    <td>unteres Quartil</td>
                    <td>Lower quartile</td>
                    </tr>
                    <tr>
                    <td></td>
                    <td>
                        Quartile unterteilen eine sortierte Datenreihe in 4 gleich große Teile. Dabei entstehen aber nicht 4 Quartile, sondern nur 3, weil mit dem Quartil die Position zwischen zwei Vierteln gemeint ist. 
                        {/* (<a href="https://www.studysmarter.de/schule/mathe/stochastik/quartile/">see source</a>) */}
                    </td>
                    <td>
                        Quartiles divide a sorted data set into 4 equal parts. However, only 3 quartiles are created, as the quartile refers to the position between two quarters.
                        {/* (<a href="https://www.studysmarter.de/schule/mathe/stochastik/quartile/">see source</a>) */}
                    </td>
                    </tr>
                    <tr>
                    <td>Median</td>
                    <td>Median</td>
                    <td>Median</td>
                    </tr>
                    <tr>
                    <td>Q3</td>
                    <td>oberes Quartil</td>
                    <td>Upper quartile</td>
                    </tr>
                    <tr>
                    <td>IQR</td>
                    <td>Interquartilabstand <br />Die Differenz zwischen Q1 und Q3 </td>
                    <td>Interquartile range <br />The difference between Q1 and Q3 </td>
                    </tr>
                    <tr>
                    <td>Minimum</td>
                    <td>Unterster Wert, der maximal 1,5 * IQR von Q1 entfernt ist</td>
                    <td>The lowest value, no more than 1.5 * IQR from Q1</td>
                    </tr>
            
                    <tr>
                    <td>Maximum</td>
                    <td>Oberster Wert, der maximal 1,5 * IQR von Q3 entfernt ist</td>
                    <td>The highest value, no more than 1.5 * IQR from Q3</td>
                    </tr>
                
                    <tr>
                    <td>Outliers</td>
                    <td>Ausreißer <br />Einzelwerte, die außerhalb des Bereichs von Minimum bis Maximum liegen. </td>
                    <td>Outliers <br />Individual values that fall outside the range between the minimum and maximum. </td>
                    </tr>
                </tbody>
                </table>

             </DialogComponent>

        <DialogComponent 
            id="defaultdialogScatter" 
            showCloseIcon={false} 
            zIndex = {9999}
            width="500px" 
            header={t('settings')}
            visible={status}
            target="#panel_12_content"
            buttons={buttons}
            open={dialogOpen}
            close={dialogClose}
            position={{ X: 'center', Y: '40' }}
        >
          <Row>                
            <Col sm={5} className='py-3'>  
                        <label htmlFor="switch1"> {t('limits.warningLimit_plural')}</label>
                    </Col>
                    <Col sm={7} className='py-3'>  
                        <SwitchComponent id="switch1" name='static' checked={staticLimit} change={() => setStaticLimit(!staticLimit)} />
                    </Col>
                    {(staticLimit == false ?  null : 
                        <>  
                            <LimitStatic name="BreathingStatic1"  value={limitStatic1} setValue={setLimitStatic1} placeholder={t('limits.upperLimit')} useActivate={false} min={limitStatic2}  />

                            <LimitStatic name="BreathingStatic2" value={limitStatic2} setValue={setLimitStatic2} placeholder={t('limits.lowerLimit')} useActivate={false} max={limitStatic1} />
                        
                            <Col sm={6} className='py-3 mb-3'>  
                                <label htmlFor="checkbox1">  {t('notification.notificationExceedingLimit')}</label>
                            </Col>
                            <Col sm={6} className='py-3 mb-3'>  
                                <CheckBoxComponent id="checkbox1" name='notificationstatic' checked={notifyStatic} change={() => setNotifyStatic(!notifyStatic)} />
                            </Col>
                        </>
                    )}
                    <Col sm={5} className='py-3'>  
                        <label htmlFor="switch2"> {t('limits.errorLimit_plural')}</label>
                    </Col>
                    <Col sm={7} className='py-3'>  
                        <SwitchComponent id="switch2" name='static' checked={staticLimit2} change={() => setStaticLimit2(!staticLimit2)} />
                    </Col>
                     {(staticLimit2 == false ?  null : 
                        <>  
                            <LimitStatic name="BreathingStatic3" value={limitStatic3} setValue={setLimitStatic3} placeholder={t('limits.upperLimit')} useActivate={false} min={(staticLimit ? limitStatic1 : limitStatic4)}  />

                            <LimitStatic name="BreathingStatic4"value={limitStatic4} setValue={setLimitStatic4} placeholder={t('limits.lowerLimit')} useActivate={false} max={(staticLimit ? limitStatic2 : limitStatic3)} />
                        
                            <Col sm={6} className='py-3 mb-3'>  
                                <label htmlFor="checkbox2">  {t('notification.notificationExceedingLimit')} </label>
                            </Col>
                            <Col sm={6} className='py-3 mb-3'>  
                                <CheckBoxComponent id="checkbox2" name='notificationstatic' checked={notifyStatic2} change={() => setNotifyStatic2(!notifyStatic2)} />
                            </Col>
                        </>
                    )}
        </Row>
        </DialogComponent>
        <div className="datecontainer">
        <DateRangePickerComponent 
            id="daterangepicker" 
            startDate={start} 
            endDate={end} 
            placeholder="Select a range" 
            change={onChange}
            format='dd.MM.yyyy'
            firstDayOfWeek = {1}
            max = {dateobj}
            />
        </div>
    </div>
    <div className="timelapse past" onClick={() => toggleDatespan(dateRange, "past")}> <TimelapsePast color={TextColor} val={" - " + dateRange + "d"}  /> </div>
    {(new Date(breathingDateRange[1].getFullYear(), breathingDateRange[1].getMonth(), breathingDateRange[1].getDate()) >= new Date( new Date(dateobj.getFullYear(), dateobj.getMonth(), dateobj.getDate())) ? null : <div className="timelapse future" onClick={() => toggleDatespan(dateRange)}> <TimelapseFuture color={TextColor} val={" + " + dateRange + "d"} /> </div>)}



    {(breathingData.length == 0 ? <h2 className='text-center pt-5'>{message}</h2> : 
    <div className='control-pane'>
        <ChartComponent 
            style={{ backgroundColor : BackgroundColor, color: TextColor}}
            background={BackgroundColor}
            primaryXAxis={primaryxAxis} 
            primaryYAxis={primaryyAxis}        
            // enableCanvas={true}
            // enablePersistence={true}
            loaded ={loaded}
            zoomSettings={{
                enableSelectionZooming: true,
                enableScrollbar: true,
                toolbarItems: ['Reset'],
                mode: "X,Y",
            }}
            title={widgetTitle}
            titleStyle={{color: TextColor, fontWeight: '400', position: 'Top', fontSize : '10'}}
            margin={margin} 
            chartArea={{ border: { width: 0 }
        }} 
            legendSettings = {{visible: false}}
            tooltip={{ enable: true}} 
             
            >
            <Inject services={[BoxAndWhiskerSeries,LineSeries, Legend, DataLabel, Category, Zoom, DateTime, StripLine, Tooltip]}/>
            <SeriesCollectionDirective>
                {/* <SeriesDirective dataSource={breathingData} xName='time' yName='breathing' name="Breathingdata" type='BoxAndWhisker' fill={PrimaryColor} opacity='0.7' marker={marker}></SeriesDirective>   */}
                <SeriesDirective dataSource={groupedData} xName='time' yName='breathing' name={widgetTitle} type='BoxAndWhisker' boxPlotMode='Normal'
                border = {{color: TextColor}}
                fill={BackgroundColor} 
                opacity='0.7' 
                showMean = {true}
                marker={marker}
                columnSpacing={.3}
                columnWidth = {.3}></SeriesDirective>  
            </SeriesCollectionDirective>
        </ChartComponent>
    </div>
)}
</>
)

}
;
export default BreathingBoxPlot;
