import DashboardPage from "../components/Dashboard";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Row, Col, Button, Card } from 'react-bootstrap';
import useAuth from "../hooks/useAuth";
import { useState, useEffect } from "react";
import MapGrid from "../components/MapGrid";

const Home = () => {

    const axiosPrivate = useAxiosPrivate();   
    const { auth } = useAuth();
    const [widgets, setWidgets] = useState([]); 
    const [statedStandorte, setStatedStandorte] = useState([]);

    // Check LocalStorage for tool id or set it to 0
    let LSselectedToolId = localStorage.getItem('selectedToolId');
    const [selectedTool, setSelectedTool] = useState( LSselectedToolId ? LSselectedToolId : 0);
   
    const handleSelectedTool = (id, newcolor) => { 
        setStatedStandorte(
            statedStandorte.map((statedStandort) =>
                statedStandort.id == id
                    ? { ...statedStandort, color: newcolor, checked: true }
                    : { ...statedStandort, color: '#000', checked: false }
            )
        );
        setSelectedTool(id);
        localStorage.setItem('selectedToolId', id); 
    };
    
    // get all molds assigned to company -> base for MapGrid
    useEffect(() => {
        const getMolds = async () => {
            try {
                const response = await axiosPrivate.get('/companies/' + auth.userInfo.companies_id, {
                });
           
                let molds = response.data.molds.filter((item) => { return item.active === true; });

                molds.forEach((element, index) => {
                    if(element.id == LSselectedToolId) {
                        molds[index].color = '#08a77f';
                        molds[index].checked = true;
                    }
                });
                molds.forEach((element, index) => {
                    if(element.latitude == null) { // Fallback = Kirchheim
                        molds[index].latitude = '48.65446934621568'; 
                        molds[index].longitude = '9.425569354937332'; 
                        molds[index].city = "No Location";
                        molds[index].country = "Missing coordinates";
                    }
                });
                setStatedStandorte([...molds]);
              
            } catch (error) {
                console.error(error);
            }
        
        }

        getMolds();
      

        return () => {
        }
    }, [])   
 
    // fetch all available widgets for mapping in dashboard
      useEffect(() => {
        const getWidgets = async () => {
            try {
                const response = await axiosPrivate.get('/widgets', {              
                });

                setWidgets([...response.data]);
            } catch (error) {
                console.error(error);
            }
        }

        getWidgets();

        return () => {
        }
    }, [])

    let allowedWidgets = [];
    // gets all widgets[] from home 
    function addAllowedWidget(widget_id){  
        let res = widgets.filter((item) => { return item.id == widget_id; });
        
        if(res[0] !== undefined){ // when match all vs. specific widget
            let widget_to_add = { 
                id: widget_id, 
                sizeX: res[0].sizeX, 
                // sizeX: 2, 
                sizeY: res[0].sizeY, 
                minSizeY: res[0].minSizeY, 
                minSizeX: res[0].minSizeX, 
                content: res[0].content,
                description: res[0].description,
                cssClass: '',
                row: res[0].row,
                thumbnail: res[0].thumbnail,
            }
                    
            allowedWidgets.push(widget_to_add);
        }
    }
    // fill allowedWidgets[] with user allowed vals
    auth.userInfo.widgets.map((widget_id) => addAllowedWidget(widget_id));
    const filteredData = statedStandorte.map(({ id, code }) => ({ id, code }));
    
    return (    
        <>   
            <Row>
                <Col xs={12}>
                    <MapGrid data={statedStandorte} handleSelectedTool={handleSelectedTool} />
                </Col>       
            </Row>        
            <DashboardPage
                allowedWidgets = { allowedWidgets }
                standorte = { statedStandorte } // raus?
                selectedTool = {selectedTool}
            />             
        </>
        
    )
}

export default Home
