import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { useState, useEffect, useRef } from "react";
import { overviewElements } from '../config/overviewElements';
import * as React from 'react';
import { Card, Row, Col, Alert } from 'react-bootstrap';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { useTranslation } from 'react-i18next';

const OverviewSetBig = () => {


    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const { t } = useTranslation();
    let dashboardObj2 = useRef(null);
    let dialogObj = useRef(null);
    const [activeElements, setActiveElements] = useState([]);
    const [panels, setPanels] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [displayAddButton, setDisplayAddButton] = useState('none');
    const [showGridLines, setShowGridLines] = useState(false);

    const [dialogVisible, setDialogVisible] = useState(false);
    const [editBtnContent, setEditBtnContent] = useState(t('buttons.edit.edit'));
    const [notice, setNotice] = useState('');
    const [noticeType, setNoticeType] = useState('');
    let item = t('widgets.widget_plural');
    let editBtnobj = useRef(null);


    useEffect(() => {
        const getSettings = async () => {
            try {
                const response = await axiosPrivate.get('/users/' + auth.userInfo.id, {              
                });
                setActiveElements(response.data.overview_settings_more !== null ? clearWidgetsAndUpdate(response.data.overview_settings_more, overviewElements) : []);   
            } catch (error) {
                console.log(error);
            }
        }

        getSettings();

        return () => {
        }
    }, [])

    const editBtnClick = (e) => {
       
        if (editBtnobj.current.element.classList.contains('e-active')) { // Editmode
            setIsDragging(true);
            setEditBtnContent(t('buttons.save'));
            setDisplayAddButton("block");
            setShowGridLines(true);
          
           
        }
        else {
            setIsDragging(false);
            setEditBtnContent(t('buttons.edit.edit'));
            setShowGridLines(false);
            setDisplayAddButton("none");
            preSave();
        }
    };
   

    const clearWidgetsAndUpdate = (activeWidets, availableWidets) => { // abgleich
        const array2Ids = new Set(availableWidets.map(item => item.id));

        activeWidets.filter(item => array2Ids.has(item.id));  // Filtert array1 und entfernt Objekte, die ID nicht( ID) in array2 enthalten ist

        return activeWidets;

    }


    const isOverviewElementchangeOverviewElement = (element) => {

        if(activeElements.find(e => e.id === element.id)){ // remove
            setActiveElements(activeElements.filter(e => e.id !== element.id))   
        } 
        else{
            setActiveElements([...activeElements, element]); //add
        }         
       
    }


    useEffect(() => {
        setPanels(widget2Panel(activeElements));
    }, [activeElements]);


    const widget2Panel= (originalArray) => {

        return originalArray.map(item => {
            const { id, sizeX, sizeY, name, row, col  } = item; // Extrahiere nur die gewünschten Felder
            return {
                id: `p${id}`,   //  zu  String mit vorangestelltem "p"
                sizeX: sizeX,    //  size in sizeX 
                sizeY: sizeY,
                content: name,
                row: row !== undefined ? row : 1, // Nur setzen, wenn row nicht existiert
                col: col !== undefined ? col : 0, // Nur setzen, wenn col nicht existiert
            };
        });
    }

    const panel2Widget = (array1, array2) => {
        array1.forEach(obj1 => {
            const matchingObj = array2.find(obj2 => obj2.id === `p${obj1.id}`);
            
            if (matchingObj) {
                // Nur col und row aus matchingObj in obj1 übernehmen
                if (matchingObj.col !== undefined) obj1.col = matchingObj.col;
                if (matchingObj.row !== undefined) obj1.row = matchingObj.row;
            }
        });
    
        return array1;
    }

    const preSave = () =>{
        let panelPos = dashboardObj2.current.serialize();
        panelPos = sortGridEntries(panelPos);
        panelPos = removeGaps(panelPos);
        setActiveElements(panel2Widget(activeElements, panelPos));
        dashboardObj2.current.panels = widget2Panel(activeElements);
        handleSubmit();
    }

    function sortGridEntries(gridData) {
        return Object.entries(gridData).sort(([keyA, valueA], [keyB, valueB]) => {
          const rowA = valueA.row || 0;
          const colA = valueA.col || 0;
          const rowB = valueB.row || 0;
          const colB = valueB.col || 0;
      
          if (rowA === rowB) {
            return colA - colB;
          }
          return rowA - rowB;
        }).map(([key, value]) => ({ key, ...value }));
      }

      function removeGaps(entries) {
        // Check if the input is an array and contains at least one entry
        if (!Array.isArray(entries) || entries.length === 0) {
            throw new Error("Invalid input: entries must be a non-empty array.");
        }
    
        // Set first entry to top left
        entries[0].col = 0;
        entries[0].row = 0;
    
        for (let i = 1; i < entries.length; i++) {
            const prevEntry = entries[i - 1]; // Get the previous entry
    
            // let newCol = prevEntry.col + prevEntry.sizeX + 1;
            let newCol = prevEntry.col + prevEntry.sizeX ;
    
            // Check ob neuer entry über col 12 -> neue row
            if (newCol + entries[i].sizeX > 12) {
                entries[i].col = 0;
                entries[i].row = prevEntry.row + 1;
            } else {
                entries[i].col = newCol;
                entries[i].row = prevEntry.row;
            }
        }
    
        return entries;
    }
    

    const handleSubmit = async e => {
      
        // e.preventDefault();
     
        try {
            const data = { id: auth.userInfo.id, overview_settings_more: activeElements };
           
            await axiosPrivate.patch(`/users`, {
                data
            })  
            .catch(function(error){
                if (error.response) {
                    console.error(error.response.data.message);
                }
            });

            // setNotice(t('messages.changesSaved'));
            // setNoticeType('success');     
    
           
        } catch (error) {
            console.log(error)    
            // setNotice(error);
            // setNoticeType('danger');
        }

    
    }

    const dialogClose = () => {
        setDialogVisible(false);
    };
 
    const isOverviewElement = (element_key) => {
        return activeElements.find(e => e.id === element_key);
    }

    const showDialogBtnClick = () => {
        setDialogVisible(true);
    };

    const dlgButtonClick = async e => {
            e.preventDefault();
         
            try {
                const data = { id: auth.userInfo.id, overview_settings_more: activeElements};
               
                await axiosPrivate.patch(`/users`, {
                    data
                })  
                .catch(function(error){
                    if (error.response) {
                        console.error(error.response.data.message);
                    }
                });
    

                setDialogVisible(false);     
            } catch (error) {
                console.log(error)    
            }
        }

    let buttons = [
        {
            click: dlgButtonClick,
            buttonModel: {
                content: t('buttons.save'),
                isPrimary: true,
            }
        }
    ];


    // function onDragStart(args) {
    //     console.log(args);
    // }
 
    return (
    <>


    <Row>
        <Col> 
       
             <h2>{t('bigView')}</h2>
           
        </Col>
        <Col>
  
        <ButtonComponent isPrimary={true} className=" float-end" ref={editBtnobj} onClick={e => editBtnClick(e)} isToggle={true} >{editBtnContent}</ButtonComponent> 
       
        </Col>
        <Col sm={12} className="my-3"> 

        <ButtonComponent isPrimary={true} className="m-auto" style={{ display: displayAddButton }}  onClick={e => showDialogBtnClick(e)} >{t('buttons.edit.editItem', { item })}</ButtonComponent>  

        
        
        {/* <Alert className="mt-2" show={notice !== ''} variant={noticeType}>{notice}</Alert> */}
        </Col>

    </Row>

    <DialogComponent
        id="listdialog" 
        width="800px" 
        height="500px" 
        visible={dialogVisible} 
        header={t('buttons.add.addItem', { item })} 
        target='#root'
        buttons={buttons}
        animationSettings={{ effect: 'Zoom' }} 
        close={dialogClose}
        ref={dialogObj}
    >
        <form id="elementCboxes">
            <ul className="oddeven">
                <li> <Row>
                <Col sm={8}> <b>Name</b> </Col>

                <Col sm={4}> <b>{t('size.sizeX')} / {t('size.sizeY')} </b> </Col>
                </Row>
                </li>
            
            {overviewElements.map((overviewElement, index) => {
              
                return (
                <>
                <li key={index}>
                    <Row>
                        <Col sm={8}>
                            <h4> {overviewElement.name}</h4>
                            {overviewElement.description}
                        </Col>
                        <Col sm={2}  className='text-center'>
                        {overviewElement.sizeX} / {overviewElement.sizeY}
                        </Col>

                        <Col sm={2}  className='text-center'>
                            <label className="cbox">
                                <input 
                                    id={`overviewElement_${overviewElement.id}`}
                                    name="element"
                                    onChange={x => isOverviewElementchangeOverviewElement(overviewElement)} //funktioniert nicht im Overlay
                                    value={overviewElement.id}
                                    checked={(isOverviewElement(overviewElement.id) ? 'checked' : '')}
                                    type="checkbox"
                                />
                                <span className="checkmark"></span>
                            </label>    
                        </Col>
                    
                    </Row>
                </li>

                </>
                )
            })}

               
                    
            </ul>
        </form>
    </DialogComponent>
    <div className="container">
        <div className="row">
      <DashboardLayoutComponent id='DashboardLayoutComponent3' 
        cellSpacing={[8, 8]} 
        panels={panels} 
        columns={12} 
        className="text-center"
        showGridLines={showGridLines}
        rows = {1}
        allowFloating={true}
        ref={dashboardObj2}
        allowDragging={isDragging} 
        // dragStart={(e) => onDragStart(e)}
        //  drag={onDrag.bind(this)} 
        //  dragStop={onDragStop.bind(this)}
        />

    </div>
    </div>
  </>);
}
export default OverviewSetBig;
