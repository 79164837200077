import { world_map } from '../data/world_map.ts';
import { MapsComponent, 
    LayersDirective, 
    LayerDirective, 
    Inject, 
    MapsTooltip, 
    Marker, 
    MarkersDirective, 
    MarkerDirective, 
    Zoom  } from '@syncfusion/ej2-react-maps';
import { ThemeContext, themes } from '../context/ThemeContext';
import { useContext, useEffect, useState } from "react";


const GeneralMap = ({ data, handleSelectedTool, handleClickFromMap, gridIsVisible}) => {
    let mapInstance;

    const currentTheme = useContext(ThemeContext);
    let TextColor = currentTheme.theme.textColor;
    let primary = currentTheme.theme.primary;
    let widgetBorder = currentTheme.theme.widgetBorder;

    
    let backgroundColor = (currentTheme.dark == true ? currentTheme.theme.backgroundColor : '#fff');
    let fillColor = (currentTheme.dark == true ? '#efeeef' : widgetBorder);
  
    
    let standorte = data;

    const [showIndicator, setShowIndicator] = useState(true); 
 
    const handleClick = (e) =>{
        handleClickFromMap();
        handleSelectedTool(e.data.id, primary);     
        
    }
    const handleMapClick = () => {
        handleClickFromMap();
        setShowIndicator(false);
    }

    useEffect(() => {
        mapInstance.refresh();
    },[standorte])
   
    return (
        <>
    <div 
    className={`control-pane ${gridIsVisible !== "isVisible" ? 'hoveroverlay' : null}`}
    id="mapbox">
    
        <MapsComponent 
         ref={m => mapInstance = m}
       
        // load={load}
        height='100%'
        width='100%'
        id="maps" 
        zoomSettings={{
            enable: true, 
            shouldZoomInitially: false, 
            mouseWheelZoom : false, 
            doubleClickZoom: true
        }} 
        markerClick={(e) => handleClick(e) }
        click={(e) => handleMapClick(e) }
        background = {backgroundColor}
        mapsArea={{
            background: backgroundColor
        }}  
        >
        <Inject services={[Marker, Zoom, MapsTooltip]}/>
            <LayersDirective>
                <LayerDirective 
                    background = {backgroundColor}
                    shapeData={world_map}
                    markerClusterSettings={{ 
                        allowClustering: true, 
                        allowClusterExpand: true,
                        allowDeepClustering: true,
                        shape: 'Circle', 
                        height: 30, 
                        width: 30, 
                        fill: '#e7cf01',
                        labelStyle: { color: '#000' },
                    }}
                    shapeSettings={{fill: fillColor } }>
                    <MarkersDirective>
                        <MarkerDirective 
                            visible={true}
                            shape= {'Circle'} 
                            colorValuePath= {'color'}
                            dataSource={standorte}
                            cssClass= "test"
                            border={{
                                color: '#fff',
                                width: 1
                            }}
                            tooltipSettings={{
                                template: '<div id="markertooltiptemplate" style="width: 170px;opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding:10px;border: 1px #abb9c6;border-radius: 4px;">' +
                                    '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${product}</center></div>' +
                                    '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' +
                                    '<div><span style="font-size:13px;color:#cccccc">Code : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${code}</span></div>' +
                                    '<div><span style="font-size:13px;color:#cccccc">City : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${city}</span></div>' +
                                    '<div><span style="font-size:13px;color:#cccccc">Country : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${country}</span></div>'  ,
                                visible: true,
                                valuePath: 'city'
                            }}
                        >
                        </MarkerDirective>                        
                    </MarkersDirective>             
                </LayerDirective>
            </LayersDirective>
        </MapsComponent>
        <div className={`menuIndicator ${gridIsVisible !== "isVisible" ? 'd-block"' : 'd-none'}`} onClick={(e) => handleMapClick(e) }></div>

    </div>
    
    </>
);
}
export default GeneralMap;
