import Gridbox from "./Gridbox";
import GeneralMap from "./GeneralMap";
import { useState, useEffect, useRef } from "react";

const MapGrid = ({ data, handleSelectedTool}) => {

    let mapgridRef = useRef();

    const [visibleGrid, setVisibleGrid] = useState(false);
    const [isVisibleclass, setIsVisibleclass] = useState('');

    const [dockedGrid, setDockedGrid] = useState(false);
    const [isDockedclass, setisDockedclass] = useState('');

     const handleClickFromMap = () => {

       
    
        if( visibleGrid == false ){
            setVisibleGrid(!visibleGrid);
            setIsVisibleclass('isVisible');
       
        }

   
     }

    const toggleDock = () => {setDockedGrid(!dockedGrid)};

    useEffect(() => {
        dockedGrid == true ? setisDockedclass('docked') : setisDockedclass('');
    },[dockedGrid])

    useEffect(() => {
        //TODO : verschwindet auch bei klick auf makrlt
       let handler = (e => {
        if(!mapgridRef.current.contains(e.target) && !document.getElementById('tbox').classList.contains('docked')){
           if(e.target.parentNode.id !== 'maps_Markers_Group'){ // click in marker

            setIsVisibleclass('');
            setVisibleGrid(false)
            setDockedGrid(false);
            setisDockedclass('');}
        }
       })
       document.addEventListener("mousedown", handler);

       return() => {
        // cleanup function, um resourcen freizugeben
        document.removeEventListener("mousedown", handler);
       }
    })


    // https://stackoverflow.com/questions/54560790/detect-click-outside-react-component-using-hooks
    // wegen clearClick map

    return ( 
            <>     
            <div className="mapgridcontainer" ref={mapgridRef}>   
                <div className="premap"></div>
                <div className="mapbox">
                    <GeneralMap 
                    data={data} 
                    handleSelectedTool={handleSelectedTool} 
                    handleClickFromMap={handleClickFromMap}
                    gridIsVisible = {isVisibleclass}
                    />
                </div>
                <div id="tbox"className={'datagridbox ' + isVisibleclass + ' ' + isDockedclass}>
                    <Gridbox 
                    data={data} 
                    handleSelectedTool={handleSelectedTool} 
                    handleDockClick={toggleDock}
                    isDocked={dockedGrid}
                    />
                </div>
            </div>
            </>
     );
}
 
export default MapGrid;