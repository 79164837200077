import { useTranslation } from 'react-i18next';

const MessageTranslate = ({ widgetKey, messageKey }) => {

  const { t } = useTranslation();

  const widgetTranslation = t('widgets.' + widgetKey); // Übersetzung für das Widget
  const translatedMessage = t(messageKey, { widgetTranslation }); // Fügt  Widget-Übersetzung in die Nachricht ein

  return (
    <>
      {translatedMessage}
    </> 
  );
};

export default MessageTranslate;
